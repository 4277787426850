<template>
  <div class="bg-primary pl-3 py-1">
    <ClientOnly>
      <CaLink
        icon="map-marker"
        color="white"
        no-padding
        @click="toggleZipcodeModal()"
      >
        {{ selectedZipcode || 'Informe seu CEP' }}
      </CaLink>
      <WsZipcodeModal
        :show-modal="showZipcodeModal"
        @handler-modal="toggleZipcodeModal()"
      />
    </ClientOnly>
  </div>
</template>

<script>
import { mapState } from 'pinia';

export default {
  name: 'Mobile',
  data() {
    return {
      showZipcodeModal: false,
    };
  },
  computed: {
    ...mapState(useShipmentStore, ['selectedZipcode']),
  },
  methods: {
    toggleZipcodeModal() {
      this.showZipcodeModal = !this.showZipcodeModal;
    },
  },
};
</script>
