<template>
  <div class="custom-bg">
    <ClientOnly>
      <WsPartnershipBanner />
    </ClientOnly>
    <WsHeaderMobile
      :is-logged="isLogged"
      :user="session"
      :show-black-header="showBlackHeader"
    />
    <WsHeaderMobileSearch :show-black-header="showBlackHeader" />
    <ZipcodeFilter />
    <main>
      <slot />
      <CaSnackbar
        v-bind="snackbar"
        @close="closedSnackbar"
      />
    </main>
    <WsFooterMobile v-if="!webView" />
  </div>
</template>

<script>
import { mapState } from 'pinia';

export default {
  computed: {
    ...mapState(useAuthStore, {
      isLogged: 'isLogged',
      session: 'getSession',
    }),
    ...mapState(useUiStore, {
      webView: 'getWebView',
      showBlackHeader: 'getShowBlackHeader',
    }),
    ...mapState(useNotificationsStore, ['snackbar']),
  },
  methods: {
    closedSnackbar() {
      useNotificationsStore().setSnackbar({
        type: '',
        show: false,
        message: '',
      });
    },
  },
};
</script>

<style lang="scss">
@media (max-width: $desktop-breakpoint) {
  @import '@petlove/lion/styles/foundation-mobile';
}

.banner-side-animation__button,
.zipecode__button,
.mobile-brands-button .button {
  background: color(primary) !important;
}

.custom-bg {
  background-color: color(neutral, bg);
}

.search-wrapper {
  background-color: color(primary);
}

.search-wrapper--black {
  background-color: color(black);
}
</style>
